import React, { useEffect, useRef, useState } from 'react';
import logo from './logo.svg';
declare const forge: any;
declare const panelbear: any;

class Engine {
  drawCanvas = document.createElement('canvas') as HTMLCanvasElement

  constructor() {
  }

  getHash = (input: string) => {
    const md = forge.md.sha256.create();
    md.update(input);
    return md.digest().toHex();
  }

  generateGhost = (id: string) => {
    const WIDTH = 128
    const HEIGHT = 128
    const NBR_HASH = 150
    let final = this.getHash(id) as string;
    let tmp = final;
    for (let i = 0; i < NBR_HASH; i++) {
      tmp = this.getHash(tmp) as string;

      final += tmp;
    }
    const board = Array.from({ length: HEIGHT }).map(e => Array.from({ length: WIDTH }).map(a => {
      return {
        value: 0,
        color: [0, 0, 0],
        seen: false,
      }
    }))
    let x = Math.floor(WIDTH / 2);
    let y = Math.floor(HEIGHT / 2);
    let index = -1;
    let chienrouge = 0;
    const dir: any = { "0": 0, "1": 0, "2": 1, "3": 1, "4": 2, "5": 2, "6": 3, "7": 3, "8": 4, "9": 4, "a": 5, "b": 5, "c": 6, "d": 6, "e": 7, "f": 7, };
    for (let char of final.split("")) {
      chienrouge += 1;
      index += 1;
      const direction = dir[char];
      if (direction === 0) {
        x += 1;
      } else if (direction === 1) {
        x += -1;
      } else if (direction === 2) {
        y += 1;
      } else if (direction === 3) {
        y += -1;
      }
      else if (direction === 4) {
        x += 1;
        y += 1;
      } else if (direction === 5) {
        x += -1;
        y += 1;
      } else if (direction === 6) {
        x += 1;
        y += -1;
      } else if (direction === 7) {
        x += -1;
        y += -1;
      }
      if (
        (x >= WIDTH)
        || (y >= HEIGHT)
        || (x < 0)
        || (y < 0)
      ) {
        x = Math.floor(WIDTH / 2);
        y = Math.floor(HEIGHT / 2);
        index = 0;
      }
      board[y][x].value = 1;
      board[y][x].color[0] = index * 0.02;
      board[y][x].color[1] = index * 0.01;
      board[y][x].color[2] = index * 0.025;
    }
    return board;
  }
}



const engine = new Engine();

function GhostDisplay(p: { board: any[][] }) {
  const div = useRef(null)
  const [background, setBackground] = useState<string>();

  useEffect(() => {
    engine.drawCanvas.width = 128
    engine.drawCanvas.height = 128
    const ctx = engine.drawCanvas.getContext("2d") as CanvasRenderingContext2D;
    ctx.fillStyle = 'white';
    ctx.clearRect(0, 0, 128, 128)
    p.board.forEach((line, y) => {
      line.forEach((point, x) => {
        if (!point.seen) {
          const color = point.color;
          ctx.fillStyle = point.value === 1 ? `rgba(${color[0]},${color[1]},${color[2]})` : `rgba(0,0,0,0)`;
          ctx.fillRect(x, y, 1, 1);
        }
      })
    });
    setBackground(engine.drawCanvas.toDataURL())
    return () => {
      // (canvas.parentNode as any).removeChild(canvas);
    }
  }, [p.board])

  return <div className="pixel" style={{
    width: '100%',
    height: '100%',
    backgroundImage: background ? `url(${background})` : "initial",
    backgroundSize: 'contain',
    backgroundPosition: "center",
    backgroundRepeat: 'no-repeat',
    imageRendering: 'pixelated',
  }}
    ref={div}>

  </div>
}


function GhostCard(p: { ghost: any }) {
  const [opa, setOpa] = useState(0);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    setOpa(1);
  }, [])

  return <div style={{
    width: "310px",
    // height: "410",
    borderBottom: '1px solid #cf6eff',
    opacity: opa,
    transition: 'opacity 0.2s',
  }} onClick={() => {
    setClicked(true);
  }}>
    <div style={{
      height: "310px",
      width: "310px",
    }}>
      <GhostDisplay board={p.ghost.board}></GhostDisplay>
    </div>
    <div style={{
      textAlign: 'center',
      color: "white",
      paddingTop: "10px",
      paddingBottom: "10px",
    }}>
      {p.ghost.seed}
    </div>
  </div>
}
let hasCliked = false;

const track = () => {
  try {
    if (!hasCliked) {
      panelbear("track", "click");
      hasCliked = true;
    }
  } catch (e) {

  }
}


function App() {
  const [back, setBack] = useState('https://i.kym-cdn.com/photos/images/newsfeed/001/240/345/02c.jpg')
  const [status, setStatus] = useState('start');
  const [minting, setMinting] = useState(false);
  const [boards, setBoards] = useState<any[]>([]);
  const [history, setHistory] = useState<any[]>([])
  const [historyCursor, setHistoryCursor] = useState(0);

  const setCatalog = async () => {
    const res = [];
    for (let i = 0; i < 1; i++) {
      while (true) {
        const seed = Math.random().toString(36).substr(2, 15)
        const boa = engine.generateGhost(seed);
        let ok = false;
        for (let y = 0; y < 128; y += 4) {
          for (let x = 0; x < 128; x += 4) {
            if (boa[y][x].color[0] > 135) {
              ok = true;
              break;
            }
          }
          if (ok) break;
        }
        if (ok || Math.random() > 0.92) {
          res.push({ board: boa, seed });
          break;
        }
      }
    }
    setHistory([...history, res])
    setBoards(res);
  }

  useEffect(() => {
    // fetch("https://cors.modez.pro").catch((e) => { })
    // setTimeout(() => {
    //   // setBack('/skull_trans.png')
    //   setTimeout(() => {
    //     setStatus('generate')
    //   }, 2000)
    // }, 20000)

    // setCatalog()
  }, [])

  return (
    <div className="App" style={{
      width: "100vw",
      height: "100vh",
      fontFamily: "verdana",
      fontSize: "5vh",
    }}>
      {status === 'start' && <>
        <div style={{
          width: "100%",
          height: "100%",
          backgroundImage: `url(${back})`,
          transition: "background 0.5s",
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
          onClick={() => {

          }}
        >

        </div>
      </>}
      {status === 'generate' && <>
        {/* {history.length > 1 && <>
          <div style={{
            position: "absolute",
            width: "10%",
            height: "10%",
            backgroundImage: "url(/left.svg)",
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            cursor: "pointer",
            top: "40%",
            left: "4px",
          }}>
          </div>
        </>} */}
        <div style={{
          display: "flex",
          overflow: "auto",
        }}>
          {boards.map((board: any) => <div key={board.seed} style={{
            width: "100vw",
            height: "100vh",
            display: "grid",
            justifyContent: "center",
            alignContent: "center",
          }}>
            <div style={{
              width: "100vw",
              height: "74vh",
              // display: "flex",
              // justifyContent: "center",
            }}>
              {minting && <>
                <div style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}>
                  <div style={{
                    textAlign: "center",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}>
                    Go to cryptoghost.art<br />
                    Use this ghost key to mint <br /><br />
                    <span style={{
                      fontSize: "1.4rem"
                    }}>
                      {board.seed}
                    </span>
                  </div>
                </div>
              </>}
              {!minting && <>
                <GhostDisplay board={board.board}></GhostDisplay>
              </>}
            </div>
            <div style={{
              textAlign: "center",
              height: "25vh",
              fontWeight: "bold",
            }}>
              <div style={{
                height: "33%",
                borderBottom: "1px solid black",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                fontWeight: "initial",
              }}
              >
                <div>
                  {board.seed}
                </div>
              </div>
              <div style={{
                height: "33%",
                borderBottom: "1px solid black",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                cursor: "pointer",
                background: "#fae8d2",
                userSelect: "none",
              }}
                onClick={() => {
                  setMinting(false)
                  setCatalog()
                  track();
                }}
              >
                <div>
                  RANDOM
                </div>
              </div>
              <div style={{
                height: "33%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                cursor: "pointer",
                background: "#fae8d2",
                userSelect: "none",
              }}
                onClick={() => {
                  track();
                  setMinting(e => !e)
                }}
              >
                <div>
                  MINT
                </div>
              </div>
            </div>
          </div>)}
        </div>
        <div>

        </div>
      </>}
    </div>
  );
}

export default App;
